<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑客户"
      @ok="handleSubmit"
    >
      <a-spin :spinning="loading">
        <a-form
          class="iotplt-compact-form"
          :form="form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          @submit="handleSubmit"
        >
          <a-form-item label="客户名称">
            <a-input
              v-decorator="['name', {
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入客户名称' },
                  { max: 50, message: '最多50个字符' },
                ]
              }]"
            />
          </a-form-item>

          <a-form-item label="所在城市">
            <a-spin :spinning="loadingRegionOption">
              <a-cascader
                :field-names="{ label: 'name', value: 'id', children: 'cities' }"
                :options="regionOptions"
                :show-search="{ regionFilter }"
                placeholder="请选择所在城市"
                v-decorator="['region']"
              />
            </a-spin>
          </a-form-item>

          <a-form-item label="登录账号">
            <a-input
              v-decorator="['username', {
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入登录账号' },
                  { pattern: /^[a-zA-Z0-9_]{4,50}$/, message: '只能由4-50位 字母、数字 或 下划线 组成' }
                ]
              }]"
            />
          </a-form-item>

          <a-form-item label="登录密码">
            <a-input-password
              autocomplete="new-password"
              v-decorator="['password', {
                rules: [
                  { min: 6, message: '最少6个字符' },
                  { max: 50, message: '最多50个字符' },
                  { pattern: /^\S.*\S$/, message: '首尾不能为空格' }
                ]
              }]"
            />
          </a-form-item>

          <a-form-item label="联系对象">
            <a-input
              v-decorator="['contact', {
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入联系对象' },
                  { max: 50, message: '最多50个字符' },
                ]
              }]"
            />
          </a-form-item>

          <a-form-item label="手机号码">
            <a-input
              v-decorator="['phone_number', {
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入手机号码' },
                  { pattern: /^1[3-9]\d{9}$/, message: '手机号码格式不正确' },
                ]
              }]"
            />
          </a-form-item>

          <a-form-item label="联系邮箱">
            <a-input
              v-decorator="['email', {
                normalize: this.$lodash.trim,
                rules: [
                  { pattern: /^[a-z0-9._%-]+@([a-z0-9-]+\.)+[a-z]{2,4}$/, message: '邮箱格式不正确' },
                ]
              }]"
            />
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findAgencyForm, updateAgency } from '@/api/agent'
import { findRegionWithoutDistrict } from '@/api/region'

export default {
  name: 'EditAgency',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      form: this.$form.createForm(this, { name: 'edit_agency' }),
      submitting: false,
      regionOptions: [],
      loadingRegionOption: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFormData()
    this.fetchRegionOptions()
  },
  methods: {
    fetchFormData() {
      this.loading = true
      findAgencyForm(this.id).then((res) => {
        if (res.code === 0) {
          this.form.setFieldsValue(res.data)
        }
        this.loading = false
      })
    },

    // 加载所在城市选项
    fetchRegionOptions() {
      this.loadingRegionOption = true
      findRegionWithoutDistrict().then((res) => {
        if (res.code === 0) {
          this.regionOptions = res.data
        }
        this.loadingRegionOption = false
      })
    },

    regionFilter(inputValue, path) {
      return path.some(option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          updateAgency(this.id, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
